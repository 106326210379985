import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { filter, take } from 'rxjs/operators';
import { ApiService } from 'src/app/shared/api.service';
import { AuthorizationFormValidators, RepeatPasswordErrorStateMatcher } from 'src/app/shared/validators';
import { UserService } from 'src/app/user.service';
import { ConsentStatusEnum, ESignerRoleEnum } from 'src/app/models/enums';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import { AppSetting } from 'src/app/shared/appSetting';
import { ILoanViewModel } from 'src/app/models/viewmodels';
import { MatDialog } from '@angular/material/dialog';
import { ConsentPopUpComponent } from '../modals/consent-pop-up/consent-pop-up.component';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';

export class BorrowerPortalSSOInfo{
  signUrl: string;
  lsid: string;
}

@Component({
  selector: 'app-borrower-portal',
  templateUrl: './borrower-portal-sso.html',
  // templateUrl: './borrower-portal.component.html',
  styleUrls: ['./borrower-portal.component.scss']
})
export class BorrowerPortalComponent {
  ssoAuthPassed: string;
  loanAccessToken: string;
  consumerConnectSiteId: string;
  authCode: string;
  errorMessage: string;
  infoMessage: string;
  displayBorrowerPortal = false;
  showLoader = true;

  securityQuestion: string = 'Security Question:';
  showActivationScreen = true;

  formMain: FormGroup;
  passwordErrorMatcher = new RepeatPasswordErrorStateMatcher();

  showRegister = false;
  showPassword = false;
  retried = false;
  nothingMoreToDo = false;
  showForms = true;
  firstName: string;
  lastName: string;
  username: string;
  password: string;
  securityAnswer: string;
  confirmPassword: string;
  confirmEmail: string;
  userIsNotLoggedIn = true;
  signInButtonDisabled = false;
  showPasswordRequirements = false;
  accountStatusResponseObject = {} as IAccountStatusResponseViewModel;
  privacyUrl: string;
  termsOfUseUrl: string;

  loanAppId: string;
  borrowerId: string;
  eSignEvent: string;
  documentId: string;
  signingRoomId: string;
  internalESign: string;
  isWetSign: string;

  constructor(private route: ActivatedRoute, private apppSetting: AppSetting, private apiService: ApiService,
    private formBuilder: FormBuilder, private authService: AuthenticationService, private userService: UserService, private dialog: MatDialog) { }

  ngOnInit(): void {
    this.route.queryParams.pipe(
      filter(params => !!params),
      take(1)
    ).subscribe(queryParam => {
      this.loanAccessToken = queryParam['token'];
      this.ssoAuthPassed = queryParam['ssoAuthPassed'] || '';
      this.consumerConnectSiteId = queryParam['siteId'] || queryParam['siteid'];
      this.authCode = queryParam['authCode'];

      this.internalESign = queryParam['internalESign'];
      this.loanAppId = queryParam['loanAppId'];
      this.borrowerId = queryParam['signerId'];
      this.eSignEvent = queryParam['event'];
      this.documentId = queryParam['documentId'];
      this.signingRoomId = queryParam['signingRoomId'];
      this.privacyUrl = window.localStorage.getItem('privacyUrl');
      this.termsOfUseUrl = window.localStorage.getItem('termsOfUseUrl');
      this.isWetSign = queryParam['isWetSign'] || 'false';

      if (this.loanAppId) {
        this.decideScreen();
      } else {
        document.location.href="/";
        return;
      }
    })
  }

  private onError(errorMessage: string, statusCode?: number) {
    this.signInButtonDisabled = false;
    this.errorMessage = errorMessage;
  }

  private buildUserData() {
    return {
      borrowerId: this.borrowerId,
      username: this.username,
      password: this.password,
      loanId: this.loanAppId,
      securityAnswer: this.securityAnswer,
      ...this.accountStatusResponseObject
    };
  }

  private decideScreen() {
    this.authService.verifyAccountStatus(this.buildUserData(), this.handleAccountStatus.bind(this), this.onError.bind(this));
  }

  private isAuthenticated(accountStatus: IAccountStatusResponseViewModel) {
    const mmlProfile = window.localStorage.getItem('mmlProfile');
    if (!mmlProfile) {
      return false;
    }

    const mmlProfileData = JSON.parse(mmlProfile);
    if (!mmlProfileData.authentication || mmlProfileData.authentication.userAccountName !== accountStatus.username) {
      return false;
    }

    const date = new Date(localStorage.getItem('mmlProfileTC'));
    const now = new Date();

    // If there is no time in local storage or if the time is more than 15 minutes ago, return false
    if (!date || (now.getTime() - date.getTime()) > 15 * 60 * 1000) {
      return false;
    }
    return true;
  }

  private handleAccountStatus(data: IAccountStatusResponseViewModel) {

    if (this.isAuthenticated(data)) {
      this.showLoader = false;
      this.userService.setUserLoggedIn(true);
      this.showForms = this.nothingMoreToDo = false;
      this.userIsNotLoggedIn = false;

      if (this.eSignEvent == "signing_complete" || this.eSignEvent == "viewing_complete" || this.eSignEvent == "session_timeout" || this.eSignEvent == "decline" || this.eSignEvent == "cancel") {
        this.nothingMoreToDo = true;
        if (this.eSignEvent != "signing_complete") {
          return;
        }
      }
      this.initiateESign();
      return;
    }

    this.showActivationScreen = !data.isActive;
    this.username = data.username;
    if (this.showActivationScreen) {
      this.securityQuestion = data.securityQuestionText;
      this.accountStatusResponseObject = data;

      this.formMain = this.formBuilder.group({
        password: ['', [
          Validators.required,
          AuthorizationFormValidators.oneLowercase,
          AuthorizationFormValidators.oneUppercase,
          AuthorizationFormValidators.oneDigit,
          AuthorizationFormValidators.specialCharacters,
          AuthorizationFormValidators.eightCharacters,
        ]],
        confirmPassword: ['', Validators.required],
        securityAnswer: ['', Validators.required]
      }, { validator: AuthorizationFormValidators.samePasswords });
    } else {
      this.formMain = this.formBuilder.group({
        password: ['', [
          Validators.required,
        ]]
      });
    }
    this.showLoader = false;
  }

  activateAccount() {
    if (this.formMain.invalid) {
      return;
    }
    this.signInButtonDisabled = true;
    this.password = this.formMain.value.password;

    this.authService.activateUser(this.buildUserData(), this.signIn.bind(this), this.onError.bind(this));
  }

  signIn() {
    this.errorMessage = '';
    this.signInButtonDisabled = true;
    this.authService.authenticateAndGrantOAuthToken(this.username, this.password, this.initiateESign.bind(this), this.onError.bind(this));
  }

  get getInfoMessage(): string {
    if (this.errorMessage || this.showLoader || this.userIsNotLoggedIn || this.nothingMoreToDo) {
      return null;
    }
    switch (this.eSignEvent) {
      case "signing_complete":
        return "You successfully completed eSigning the document!";
      case "viewing_complete":
        return "You successfully completed viewing the document";
      case "session_timeout":
        return "Your session timed out!";
      case "decline":
        return "You declined eSigning the document";
      case "cancel":
        return "You canceled eSigning the document";
      default:
        return "*Contact your loan officer or check email in order to eSign documents";
    }
  }

  private formulateEventDetail(title, description = ''): Record<string, string> {
    if (description !== '') {
      description = ` ${description}. `;
    }
    return { title, description };
  }
  get getEventDetail(): Record<string, string> {
    switch (this.eSignEvent) {
      case "signing_complete":
        return this.formulateEventDetail(
          "By signing the document you've completed the process.",
          "You're all set");
      case "viewing_complete":
        return this.formulateEventDetail("You successfully completed viewing the document")
      case "decline":
        return this.formulateEventDetail("You declined eSigning the document")
      case "cancel":
        return this.formulateEventDetail("You canceled eSigning the document")
      default:
        return this.formulateEventDetail("Contact your loan officer or check email in order to eSign documents")
    }
  }

  private getESignReturnUrl(): string {
    let currentUrl = window.location.href;
    if (currentUrl.includes('?')) {
      currentUrl = `${currentUrl}&ssoByEsignSite=true`;
    } else {
      currentUrl = `${currentUrl}?ssoByEsignSite=true`;
    }
    return encodeURIComponent(currentUrl);
  }

  private initiateESign(): void {
    this.userService.setUserLoggedIn(true);

    if (!this.internalESign) {
      this.showLoader = false;
      console.warn(`One of required params is missing`);
      this.errorMessage = `One of required params is missing!`;
      return;
    }

    if (!!this.eSignEvent && this.eSignEvent == "signing_complete") {
      this.apiService.HandleSignatureEvent(this.loanAppId, this.documentId, this.borrowerId, ESignerRoleEnum.Borrower, this.signingRoomId, this.eSignEvent).subscribe(
        (data) => {
          this.showLoader = false;
        },
        (error: HttpErrorResponse) => {
          this.showLoader = false;
          console.warn(`Error while handling ESign event`, error);
          this.errorMessage = `Error while handling ESign event: ${error.message}`;
        });
    }
    else {
      this.apiService.getLoanApp(this.loanAppId, this.borrowerId).subscribe(
        (data) => {
          const loanVm = data.result as ILoanViewModel;
          this.showLoader = false;
          //check if current borrower eConsented and open modal; if accepted, update status in DB and then GetESignUrl
          const currentBorrower = loanVm.transactionInfo.borrowers.find(x => x.borrowerId == this.borrowerId.toLowerCase());
          if (currentBorrower && currentBorrower.eConsent){
            if (currentBorrower.eConsent.consentStatus !== ConsentStatusEnum.Accept) {
            const disclosureStatus = loanVm.transactionInfo.loanApplications[0].disclosureStatusDetails.disclosureStatus;
            const docDelivery = loanVm.transactionInfo.loanApplications[0].docDelivery;

            const eConsentModal = this.dialog.open(ConsentPopUpComponent, {
              width: '820px',
              data: {
                parentLoanId: loanVm.loanId, borrower: currentBorrower, docDelivery: loanVm.transactionInfo.loanApplications[0].docDelivery,
                disclosureStatus: disclosureStatus, isLegalDisclaimerConfirmed: loanVm.isLegalDisclaimerConfirmed, encompassBorrowerPairId: loanVm.transactionInfo.loanApplications[0].encompassBorrowerPairId
              },
              disableClose: true, closeOnNavigation: false
            });
            eConsentModal.afterClosed().subscribe(modalResponse => {
              console.log('Modal Closed');
              if (modalResponse && modalResponse.borrowerDeclined === true) {
                this.logOut();
              }
              else if (modalResponse.wasEconsentSuccessful) {
                this.apiService.GetESignUrl(this.loanAppId, this.borrowerId, ESignerRoleEnum.Borrower, this.isWetSign, this.getESignReturnUrl()).subscribe(
                  (getEsignUrlResponse) => {
                    if (getEsignUrlResponse.response) {
                      return this.apiService.trackPreviewDocs(this.loanAppId, this.borrowerId).subscribe(
                        (trackResponse) => {
                          window.location.href = getEsignUrlResponse.response;
                          return;
                      });
                    }
                    this.logOut();
                  },
                  (error: HttpErrorResponse) => {
                    this.showLoader = false;
                    console.warn(`Error while getting ESign URL`, error);
                    this.errorMessage = `An error occurred while getting ESign URL: ${error.message}`;
                    this.logOut();
                  });
              }
            },
              (error: HttpErrorResponse) => {
                this.showLoader = false;
              });
          }
          else {
            this.apiService.GetESignUrl(this.loanAppId, this.borrowerId, ESignerRoleEnum.Borrower, this.isWetSign, this.getESignReturnUrl()).subscribe(
              (getEsignUrlResponse) => {
                return this.apiService.trackPreviewDocs(this.loanAppId, this.borrowerId).subscribe(
                  (trackResponse) => {
                    this.showLoader = false;
                    if (getEsignUrlResponse.response) {
                      window.location.href = getEsignUrlResponse.response;
                    }
                    else {
                      this.logOut();
                    }
                  });
              },
              (error: HttpErrorResponse) => {
                this.showLoader = false;
                console.warn(`Error while getting ESign URL`, error);
                this.errorMessage = `An error occurred while getting ESign URL: ${error.message}`;
                this.logOut();
              });
          }}
        },
        (error: HttpErrorResponse) => {
          this.showLoader = false;
          console.warn(`Error occured while loading the loan.`, error);
          this.errorMessage = `An error occurred while loading the loan: ${error.message}`;
          this.logOut();
        });
    }
  }

  logOut()
  {
    this.authService.clearCookie('mml-auth-token');
    this.userService.setUserLoggedIn(false);
    localStorage.removeItem('mmlProfile');
    localStorage.removeItem('mml-auth-token');
    window.location.href = '/';
  }
}


interface IAccountStatusResponseViewModel {
  isActive: Boolean,
  accountExists: Boolean,
  username: string,
  securityQuestionText: string,
  securityQuestionId: number,
  divisionId: number,
  branchId: string,
  channelId: number,
}
