import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ChangeDetectorRef  } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { ApiService } from './shared/api.service';
import { AuthenticationService } from './shared/authentication.service';
import { UserService } from './user.service';
import { catchError, map } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  errorMessage: string;
  invalidSSOConfiguration: string;
  withCloverTemplate: boolean;
  isUserLoggedIn$ = this.userService.userLoggedIn$;
  consumerSiteConfiguration$: Record<string, any> = {};
  customFooterHtml$: Observable<string>;
  showFooter = false;

  constructor(private route: ActivatedRoute, private userService: UserService, private apiService: ApiService, 
    private authService: AuthenticationService, private sanitizer: DomSanitizer, private cdref: ChangeDetectorRef) { }

  ngOnInit(): void {
    this.authService.getCompanyLogo();
    this.withCloverTemplate = this.shouldUseCloverTemplate();
    this.customFooterHtml$ = this.apiService.getConsumerSiteConfiguration().pipe(map((config) => {
      config.forEach(configItem => this.consumerSiteConfiguration$[configItem.key] = configItem);
      let footer;
      if (this.consumerSiteConfiguration$['clover.global.footer.html'] && this.consumerSiteConfiguration$['clover.global.footer.html'].value) {
        footer = this.consumerSiteConfiguration$['clover.global.footer.html'].value;
      }
      
      if (this.consumerSiteConfiguration$['Legal.PrivacyPolicy.URL.CS'] && this.consumerSiteConfiguration$['Legal.PrivacyPolicy.URL.CS'].value) {
        const privacyUrl = this.consumerSiteConfiguration$['Legal.PrivacyPolicy.URL.CS'].value;
        window.localStorage.setItem('privacyUrl', privacyUrl);
        
      }
      
      if (this.consumerSiteConfiguration$['Legal.TermsOfUse.URL.CS'] && this.consumerSiteConfiguration$['Legal.TermsOfUse.URL.CS'].value) {
        const termsOfUseUrl = this.consumerSiteConfiguration$['Legal.TermsOfUse.URL.CS'].value;
        window.localStorage.setItem('termsOfUseUrl', termsOfUseUrl);
      }
      if (footer) {
        return footer;
      }
      this.showFooter = true;
      return null;
    })).pipe(catchError((_: HttpErrorResponse, caught) => {
      this.showFooter = true;
      return caught;
    }));

    this.route.queryParams.subscribe(queryParam => {
      this.invalidSSOConfiguration = queryParam['invalidSSOConfiguration'];
      if (this.invalidSSOConfiguration === "1")
      {
        console.warn(`Invalid SSO Configuration`);
        this.errorMessage = `Invalid SSO Configuration`;
      }
    })
  }

  ngAfterContentChecked() {
    this.cdref.detectChanges();
  }

  get companyLogo() {
    return this.sanitizer.bypassSecurityTrustUrl(this.authService.companyLogo);
  }

  onLogout(): void {
    if (this.authService.checkIfUserIsLoggedIn()) {
      this.authService.clearCookie('mml-auth-token');
      this.userService.setUserLoggedIn(false);
    }

    this.wipeSigningStates()
  }

  wipeSigningStates(): void {
    const newParams = new URLSearchParams();
    const params = new URLSearchParams(window.location.search);
    const blacklistedParams = ['documentId', 'signingRoomId', 'event'];
    params.forEach((value, key) => {
      if (!blacklistedParams.includes(key)) {
        newParams.append(key, value);
      }
    });

    const newUrl = `${window.location.pathname}?${newParams.toString()}`;
    window.location.replace(newUrl);
  }

  shouldUseCloverTemplate(): boolean {
    const cloverPages = [
      '/borrowerportal'
    ];
    return cloverPages.includes(document.location.pathname);
  }
}
