import { HttpClient, HttpResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError, Observable } from "rxjs";
import { map, tap, catchError } from "rxjs/operators";
import { ConsentStatusEnum, DisclosureStatusEnum, DocDeliveryTypeEnum, ESignerRoleEnum } from "../models/enums";
import { IBorrowerViewModel } from "../models/viewmodels";
import { AppSetting } from "./appSetting";


// export interface IAuthenticationResponseViewModel {
//   authentication: IAuthenticationViewModel;
//   authenticationRequestType: AuthenticationRequestTypeEnum;
//   authenticationStatus: AuthenticationStatusEnum;
//   errorMessage: string;
//   succeeded: boolean;
//   token: string;
// }

export interface IChannelViewModel {
  borrowerSiteURL: string;
  channelId: number;
  channelManagerId?: number;
  city: string;
  cposDefaultLO: number;
  cposUrlApproved: boolean;
  disclosuresLenderName: string;
  displayName: string;
  divisions: IDivisionViewModel[];
  isWholesale?: boolean;
  name: string;
  nmlsNumber: string;
  phone: string;
  state: string;
  streetAddress: string;
  theme: string;
  zip: string;
}
export interface IDivisionViewModel {
  branches: IBranchViewModel[];
  city: string;
  cposDefaultLO: number;
  cposUrlApproved: boolean;
  disclosuresLenderName: string;
  displayName: string;
  divisionId: number;
  divisionManagerId?: number;
  divisionName: string;
  email: string;
  isSeparateBusinessEntity: boolean;
  nmlsNumber: string;
  phone: string;
  state: string;
  streetAddress: string;
  theme: string;
  zip: string;
}
export interface IBranchViewModel {
  addressId?: string;
  branchId: string;
  branchManager?: number;
  city: string;
  defaultBrokerCompensationAmount?: number;
  defaultBrokerCompensationPercent?: number;
  disclosuresLenderName: string;
  displayName: string;
  licenses: object[];
  maxCompensation?: number;
  minCompensation?: number;
  name: string;
  phone: string;
  priceApprover?: number;
  rateApprover?: number;
  state: string;
  streetAddress: string;
  theme: string;
  zip: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient, private appSetting: AppSetting) { }

  public GetSSOLoginUrlForBorrowerPortal() {
    const url = `${this.appSetting.apiUrl}/api/security/GetSSOLoginUrlForBorrowerPortal?eSignUrl=${encodeURIComponent(window.location.href)}`;
    return this.http.get<any>(url);
  }

  public GetESignUrl(loanAppId: string, signerId: string, signerRole: ESignerRoleEnum, isWetSign: string, returnUrl: string) {
    const url = `${this.appSetting.apiUrl}/api/ESigningService/GetSigningRoomUrl?loanAppId=${loanAppId}&signerId=${signerId}&signerRole=${signerRole}&isWetSign=${isWetSign}&returnUrl=${returnUrl}`;
    return this.http.get<any>(url);
  }

  public HandleSignatureEvent(loanAppId: string, documentId: string, signerId: string, signerRole: ESignerRoleEnum, signingRoomId: string, signEvent: string) {
    const url = `${this.appSetting.apiUrl}/api/ESigningService/HandleSignatureEvent?loanAppId=${loanAppId}&documentId=${documentId}&signerId=${signerId}&signerRole=${signerRole}&signingRoomId=${signingRoomId}&signEvent=${signEvent}`;
    const token = JSON.parse(localStorage.getItem('mmlProfile')).token;
    return this.http.get<any>(url, { headers: { Authorization: `Bearer ${token}` } }  );
  }

  public SignOutSSO() {
    let lsid = localStorage.getItem('settings-app-lsid');
    const url = `${this.appSetting.apiUrl}/api/security/SignOutSSO`;
    const relayState = encodeURIComponent(window.location.origin);

    const params = {
      lsid,
      relayState
    };

    return this.http.post(url, null, { params, responseType: 'text' });
  }

  public getCompanyLogo() {
    const url = `${this.appSetting.apiUrl}/api/CompanyProfile/BranchLogo/`;
    return this.http.get<any>(url);
  }

  public getCompany() {
    const callTimestamp = new Date().getTime().toString();
    const url = `${this.appSetting.apiUrl}/api/CompanyProfileService/GetCompanyBasicInfo/`;
    const params = {
      callTimestamp,
      userId: 'User',
    };
    return this.http.get<any>(url, { params });
  }

  public async verifyAccountStatus(data: any) {
    const userAccountId = '123456'; // This odd value is the account id for the esign site.
    const callTimestamp = new Date().getTime().toString();
    const registerUrl = `${this.appSetting.apiUrl}/api/users/status`;

    const requestBody = {
      accountStatusRequestViewModel: {
        accountStatus: {
          borrowerId: data.borrowerId,
          loanId: data.loanId
        },
      },
    };

    const params = {
      userAccountId,
      callTimestamp,
    };

    return this.http
      .post<any>(registerUrl, requestBody, { observe: 'response', params });
  }

  /**
   * Used to create a new user and loan
   * @param data
   */
  public async activateUser(data: any) {
    const userAccountId = '123456'; // This odd value is the account id for the esign site.
    const callTimestamp = new Date().getTime().toString();
    const registerUrl = `${this.appSetting.apiUrl}/api/users/register`;

    const requestBody = {
      authenticationRequestViewModel: {
        authenticationRequestType: 4,
        authentication: {
          userAccountName: data.username,
          ...data
        },
      },
    };

    const params = {
      userAccountId,
      callTimestamp,
    };

    var useCredentials = false;

    return this.http
      .post<any>(registerUrl, requestBody, { observe: 'response', params, withCredentials: useCredentials });
  }

  // /**
  //  * Used by both login and register to handle an authenticated response
  //  * @param response
  //  */
  // public handleAuthResponseHeaders(response: HttpResponse<any>): void {
  //   // Store the auth token
  //   const token = response.headers.get('authorization');
  //   if (token) {
  //     this.settings.token = token;
  //     this.isLoggedIn = true;
  //   } else {
  //     console.log('Missing Oauth token in the response header', response.url, response.body, response.status, response.headers);
  //   }
  // }

  // /**
  //  * Used by both login and register to handle an authenticated response
  //  * @param response
  //  */
  // public handleAuthResponseBody(response: IAuthenticationResponseViewModel): void {
  //   // console.log('handleAuthResponseBody', this.settings.userId);
  //   if (!(response && response.authentication)) {
  //     console.error(`AUTH ENDPOINT DID NOT RETURN AUTHENTICATION DATA`);
  //   }
  //   // Store the loan ID when first logging in
  //   if (!this.settings.loanId) {
  //     this.settings.loanId = response.authentication.loanId || null;
  //   }
  //   // Store the user ID when first logging in
  //   this.settings.userId =
  //     (response.authentication.accountIds &&
  //       response.authentication.accountIds.length &&
  //       response.authentication.accountIds[0] &&
  //       response.authentication.accountIds[0].toString &&
  //       response.authentication.accountIds[0].toString()) ||
  //     null;
  //   // Store the userName when first logging in
  //   this.settings.userName = response.authentication.userAccountName || null;
  //   this.settings.userFullName = response.authentication.firstName + ' ' + response.authentication.lastName || null;
  //   this.settings.ssoUser = response.authentication.isSSOUser || false;

  //   this.analytics.mixpanelSuperProps({ 'Loan Id': this.settings.loanId });
  //   this.analytics.mixpanelSuperProps({ 'CV Guid': this.settings.loanId });

  //   this.checkAuthorization = false;
  //   this.keepAlive();
  //   this.sessionExpired = false;

  //   // Clear the opened loans session cookie in case the browser (e.g., Chrome, Edge) settings have a
  //   // pick-up-where-you-left-off option that keeps cookies accross sesions.
  //   this.clearOpenedLoanIdsCookie();
  // }

  public authenticateAndGrantOAuthToken(username: string, password: string) {
    const url = `${this.appSetting.apiUrl}/api/SecurityService/AuthenticateAndGrantConsumerSiteOAuthToken?serviceAccountUserAccountId=123456`;

    const formData = this.getFormDataForAuth();
    formData.append('username', username);
    formData.append('password', password);

    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  public grantOauthTokenForConsumer(username: string, password: string) {
    const url = `${this.appSetting.apiUrl}/api/SecurityService/GrantOauthTokenForConsumer`;
    let lsid = localStorage.getItem('settings-app-lsid');

    const formData = this.getFormDataForAuth();
    formData.append('username', username);
    formData.append('password', password);
    formData.append('leadSourceId', lsid);
    formData.append('loanId', null);

    return this.http.post<any>(url, formData, { observe: 'response' });
  }

  private getFormDataForAuth(): FormData {
    const formData = new FormData();
    formData.append('client_id', `MmlOAuthClientDescription`);
    formData.append('redirect_uri', `https://qa01concierge.newleaflending.com`);
    formData.append('scope', `scope-mml-lc`);
    formData.append('response_type', `token`);
    return formData;
  }

  authenticateWithSSSToken() {
    const formData = this.getFormDataForAuth();
    const endpoint = `${this.appSetting.apiUrl}/api/security/AuthenticateWithSSOToken`;
    return this.http.post<any>(endpoint, formData, { observe: 'response', withCredentials: true });
  }

  trackPreviewDocs(loanAppId: string, borrowerId: string) {
    const endpoint = `${this.appSetting.apiUrl}/api/ESigningService/trackPreviewDocs?loanApplicationId=${loanAppId}&borrowerId=${borrowerId}`;
    return this.http.get<any>(endpoint);
  }

  getLoanApp(loanAppId: string, borrowerId: string) {
    const formData = this.getFormDataForAuth();
    const endpoint = `${this.appSetting.apiUrl}/api/Loan/EagerLoadESignPortal?loanAppid=${loanAppId}&borrowerId=${borrowerId}&userAccountId=-1`;
    return this.http.get<any>(endpoint);
  }

  /**
 * Save the eConsent status for borrower
 * @param borrower
 * @param parent loan id
 * @param acceptConsent Is eConsent being accepted
 * @param disclosureStatus Disclosure status of the loan
 * @param docDelivery DocDelivery of the loan
 * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
 */
  saveEconsentStatus(borrower: IBorrowerViewModel, loanId: string, showCommunicationConsentText: boolean, disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): Observable<any> {
    const callTimestamp = new Date().getTime().toString();
    const url = `${this.appSetting.apiUrl}/api/ConsumerSiteService/BorrowerEConsent`;
    const params = {
      callTimestamp,
      userId: 'User',
    };

    if (borrower.eConsent) {
      borrower.eConsent.source = window.location.origin;
    }

    // Create a API request for each borrower that needs to be saved
    return this.http.post<any>(url, {
      borrowerViewModel: borrower,
      documentDeliveryType: this.getDocDelivery(disclosureStatus, docDelivery, numberOfConsentingBorrowers),
      loanId: loanId,
      communicationConsentTextAvailable: showCommunicationConsentText
    }, { params }).pipe(map((response) => {
      if (response && response.errorMsg) {
        throw new Error(response.errorMsg);
      } else {
        return response;
      }
    }));
  }

  updateEConsentDisclosureTrackingData(borrower: IBorrowerViewModel, encompassBorrowerPairId: string) {
    const endpoint = `${this.appSetting.apiUrl}/api/ConsumerSiteService/UpdateEConsentDisclosureTrackingData`;
    return this.http.post<any>(endpoint, {borrowerViewModel: borrower, encompassBorrowerPairId: encompassBorrowerPairId});
  }

  public getESignPortalonfiguration() {
    let lsid = localStorage.getItem('settings-app-lsid');
    const url = `${this.appSetting.apiUrl}/api/Configuration/ESignPortal?leadSourceId=${lsid}`;
    return this.http.get<any>(url);
  }

  public getConsumerSiteConfiguration() {
    let lsid = localStorage.getItem('settings-app-lsid');
    const url = `${this.appSetting.apiUrl}/api/Configuration/ConsumerSiteConfiguration?leadSourceId=${lsid}`;
    return this.http.get<any>(url);
  }

  /**
   * Set docDelivery in order to disclsoure status
   * @param disclosureStatus Disclosure status of the loan
   * @param docDelivery DocDelivery of the loan
   * @param numberOfConsentingBorrowers Number of borrowers that accept eConsent
   */
  private getDocDelivery(disclosureStatus: DisclosureStatusEnum, docDelivery: DocDeliveryTypeEnum, numberOfConsentingBorrowers: number): DocDeliveryTypeEnum {
    const disclosureCreatedEnums = [DisclosureStatusEnum.DisclosuresCreated, DisclosureStatusEnum.DisclosuresSigned, DisclosureStatusEnum.WaitingToBeSigned];

    if (disclosureCreatedEnums.some(x => x == disclosureStatus)) {
      return docDelivery;
    } else {
      return numberOfConsentingBorrowers > 0 ? DocDeliveryTypeEnum.Electronic : DocDeliveryTypeEnum.Mail
    }
  }
}
