import { ViewEncapsulation } from '@angular/compiler/src/core';
import { ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Observable, Subject, Unsubscribable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { ConsentStatusEnum, DisclosureStatusEnum } from 'src/app/models/enums';
import { IBorrowerViewModel } from 'src/app/models/viewmodels';
import { ApiService } from 'src/app/shared/api.service';
import { TcpaVerbiageService } from 'src/app/shared/tcpa-verbiage.service';
import { HintComponent } from '../hint/hint.component';

interface ModalInputData {
  parentLoanId: string;
  borrower: IBorrowerViewModel;
  docDelivery: number;
  disclosureStatus: DisclosureStatusEnum;
  isLegalDisclaimerConfirmed: boolean;
  encompassBorrowerPairId: string;
}

@Component({
  selector: 'app-consent-pop-up',
  templateUrl: './consent-pop-up.component.html',
  styleUrls: ['./consent-pop-up.component.scss']
})
export class ConsentPopUpComponent implements OnInit {

  /** Show saving indicator */
  @Input() isSaving = false;
  /** Used to display errors in the UI */
  @Input() error: string;
  /** Boolean set by the modal to force single user mode */
  @Input() isForcedSingleBorrowerMode: boolean;
  /** Toggle full / condensed view of the consent text */
  showAllText = false;
  /** eConsent text shown in the UI */
  eConsentText: string = null;
  /** Status that is selected in the UI */
  selectedConsentStatus: ConsentStatusEnum;
  /** Used in UI to compare */
  consentStatusEnum = ConsentStatusEnum;
  /** Used to show WordPress variables in template */
  showCommunicationConsentText = false;
  /** Used to show variable in template */
  companyName: string;
  private subscriptions: Unsubscribable[] = [];
  tcpaDisclaimerMarkup: string = null;
  privacyUrl: string;
  privacyHtml: string;
  termsUrl: string;
  termsHtml: string;
  thirdPartyUrl: string;
  thirdPartyHtml: string;
  @ViewChild('tcpa', { static: false }) tcpa: ElementRef<HTMLElement>;
  eSignPortalConfigurationLoaded = new Subject<boolean>();


  radioControl = new FormControl();

  constructor(public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: ModalInputData, private ref: ChangeDetectorRef, private apiService: ApiService, private renderer: Renderer2,
    private tcpaVerbiageService: TcpaVerbiageService, private dialog: MatDialog) { }

  ngOnInit() {
    this.eConsentText = 'Test';
    this.showCommunicationConsentText = true;
    this.apiService.getESignPortalonfiguration().subscribe((config) => {
      this.showCommunicationConsentText = config.find(x => x.key == 'config.area.tcpa.enabled').value === 'true' && !this.data.isLegalDisclaimerConfirmed;
      this.eConsentText = config.find(x => x.key == 'sys.conf.econsent').value || null;
      this.tcpaDisclaimerMarkup = config.find(x => x.key == 'sys.conf.tcpa').value || null;
      this.privacyUrl = (config.find(x => x.key == 'clover.editor.footer.privacypolicyurl').value || config.find(x => x.key == 'Legal.PrivacyPolicy.URL.CS').value) || '#';
      this.privacyHtml = config.find(x => x.key == 'Legal.PrivacyPolicy.Manual.CS').value || null;
      this.termsUrl = (config.find(x => x.key == 'clover.editor.footer.termsofuseurl').value || config.find(x => x.key == 'Legal.TermsOfUse.URL.CS').value) || '#';
      this.termsHtml = config.find(x => x.key == 'Legal.TermsOfUse.Manual.CS').value || null;
      this.thirdPartyUrl = config.find(x => x.key == 'Legal.ThirdPartySuppliers.URL.CS').value || '#';
      this.thirdPartyHtml = config.find(x => x.key == 'Legal.ThirdPartySuppliers.Manual.CS').value || null;
      this.eSignPortalConfigurationLoaded.next(true);
    });
  }

  ngAfterContentInit() {
    // Clear errors
    this.setError(null);
    this.eSignPortalConfigurationLoaded.asObservable().pipe(
      filter(Boolean),
      take(1)
    ).subscribe(() => {
      if (this.showCommunicationConsentText) {
        const { nativeElement: tcpa } = this.tcpa;
        this.tcpaVerbiageService.applyVerbiage(this.renderer, tcpa, this.subscriptions, {
          onTermsClick: () => this.onTermsClick(),
          onPrivacyClick: () => this.onPrivacyClick(),
          onThirdPartyClick: () => this.onThirdPartyClick(),
          tcpaDisclaimerMarkup: this.tcpaDisclaimerMarkup,
          companyName: this.companyName
        });
      }
    });

  }

  /** Show more / hide text */
  onReadMoreClick(): boolean {
    this.showAllText = !this.showAllText;
    this.ref.markForCheck();
    return false;
  }

  /** Toggle saving flag */
  setIsSaving(isSaving = true): void {
    this.isSaving = isSaving;
    this.ref.markForCheck();
  }

  /**
   * Show error message in UI
   * @param error Message to display in UI
   */
  setError(error: string = `An error occurred. Please try again.`): void {
    this.error = error;
    this.ref.markForCheck();
  }

  /**
   * Handle terms of use link click
   */
  onTermsClick(): void {
    return this.openModalOrNewWindow(this.termsHtml, 'Terms of Use', this.termsUrl);
  }

  /**
   * Handle privacy policy link click
   */
  onPrivacyClick(): void {
    return this.openModalOrNewWindow(this.privacyHtml, 'Privacy Policy', this.privacyUrl);
  }

  /**
   * Handle third party supplier link click
   */
  onThirdPartyClick(): void {
    return this.openModalOrNewWindow(this.thirdPartyHtml, 'Third Party Suppliers', this.thirdPartyUrl);
  }

  onSubmit(): void {
    // Trigger validation
    this.radioControl.setValidators([Validators.required]);
    this.radioControl.updateValueAndValidity();
    const selectedConsentStatus = this.selectedConsentStatus;
    const radioControlValue = this.radioControl.value;
    const selectedBorrower = this.data.borrower;
    const originalEconsentStatuses = selectedBorrower.eConsent && selectedBorrower.eConsent.consentStatus || null;

    // Clear errors
    if (radioControlValue == null || radioControlValue == undefined) {
      this.radioControl.setErrors({ required: true });

      return;
    }

    this.setError(null);

    if (selectedBorrower.eConsent) {
      selectedBorrower.eConsent.consentStatus = selectedConsentStatus;
    }

    this.setIsSaving(true);

    this.apiService.updateEConsentDisclosureTrackingData(selectedBorrower, this.data.encompassBorrowerPairId).subscribe(() => {
      this.apiService.saveEconsentStatus(selectedBorrower, this.data.parentLoanId, this.showCommunicationConsentText, this.data.disclosureStatus, this.data.docDelivery, 1).subscribe(() => {
        this.setIsSaving(false);
        this.dialogRef.close({ wasEconsentSuccessful: selectedBorrower.eConsent.consentStatus == ConsentStatusEnum.Accept, borrowerDeclined: selectedBorrower.eConsent.consentStatus == ConsentStatusEnum.Decline });
      }, () => {
        this.setError(`An error occurred while saving. Please try again.`);
        this.setIsSaving(false);
      });
    }, () => {
      this.setError(`An error occurred while updating disclosure tracking log. Please try again.`);
      this.setIsSaving(false);
    });
  }

  private openModalOrNewWindow(modalHtml: string, modalTitle: string, newWindowUrl: string): void {
    setTimeout(() => {
      if (modalHtml) {
        this.dialog.open(HintComponent, {
          width: '820px',
          data: { title: modalTitle, body: modalHtml }, panelClass: 'custom-hint-dialog'
        });
      } else {
        window.open(newWindowUrl, '_blank');
      }
    });
    return;
  }
}
