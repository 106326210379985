import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { filter, take } from 'rxjs/operators';
import { ESignerRoleEnum } from 'src/app/models/enums';
import { ApiService } from 'src/app/shared/api.service';
import { AuthenticationService } from 'src/app/shared/authentication.service';
import { UserService } from 'src/app/user.service';

@Component({
  selector: 'app-lo-portal',
  templateUrl: './lo-portal.component.html',
  styleUrls: ['./lo-portal.component.scss']
})
export class LoPortalComponent {
  showLoader = false;
  username: string;
  password: string;
  errorMessage: string = null;
  loanAppId: string;
  signerUsername: string;
  eSignEvent: string;
  documentId: string;
  signingRoomId: string;
  signInButtonDisabled = false;
  isUserLoggedIn$ = this.userService.userLoggedIn$;

  constructor(private route: ActivatedRoute, private apiService: ApiService, private authService: AuthenticationService, private userService: UserService, private sanitizer: DomSanitizer, private cookieService: CookieService) { }

  ngOnInit() {
    this.route.queryParams.pipe(
      filter(params => !!params),
      take(1)
    ).subscribe(queryParam => {
      this.loanAppId = queryParam['loanAppId'];
      this.documentId = queryParam['documentId'];
      this.signingRoomId = queryParam['signingRoomId'];
      this.eSignEvent = queryParam['event'];
      this.signerUsername = queryParam['loUsername'];

      // Check if User is logged in, if yes redirect to , if no display Sign in
      if (this.authService.checkIfUserIsLoggedIn()) {
        this.onLoginSuccess();
      }
    })
  }

  signIn() {
    if (this.username.toLowerCase() != this.signerUsername.toLowerCase()){
      this.onError('Whoops, the Email or Password is incorrect');
      return;
    }

    this.errorMessage = '';
    this.signInButtonDisabled = true;
    this.authService.authenticateAndGrantOAuthToken(this.username, this.password, this.onLoginSuccess.bind(this), this.onError.bind(this));
  }

  private onLoginSuccess() {
    this.userService.setUserLoggedIn(true);
    this.signInButtonDisabled = false;
    if (!!this.eSignEvent) {
      this.showLoader = true;
      this.apiService.HandleSignatureEvent(this.loanAppId, this.documentId, "LO", ESignerRoleEnum.LO, this.signingRoomId, this.eSignEvent).subscribe(
        (data) => {
          this.showLoader = false;
        },
        (error: HttpErrorResponse) => {
          this.showLoader = false;
          console.warn(`Error while handling ESign event`, error);
          this.errorMessage = `Error while handling ESign event: ${error.message}`;
        });
      return;
    }
    else if (this.loanAppId) {
      this.showLoader = true;
      this.apiService.GetESignUrl(this.loanAppId, "LO", ESignerRoleEnum.LO, 'false', encodeURIComponent(window.location.href)).subscribe(
        (data) => {
          this.showLoader = false;
          if (data.response){
            window.location.href = data.response;
          }
          else {
            this.userService.setUserLoggedIn(false);
            this.clearStoredSiteData();
          }
        },
        (error: HttpErrorResponse) => {
          this.showLoader = false;
          console.warn(`Error while getting ESign URL`, error);
          this.errorMessage = `An error occurred while getting ESign URL: ${error.message}`;
        });
    }
  }

  private onError(errorMessage: string) {
    this.signInButtonDisabled = false;
    this.errorMessage = errorMessage;
  }

  get getInfoMessage(): string {
    if (this.errorMessage || this.showLoader) {
      return null;
    }
    switch (this.eSignEvent) {
      case "signing_complete":
        return "You successfully completed eSigning the document!";
      case "viewing_complete":
        return "You successfully completed viewing the document";
      case "session_timeout":
        return "Your session timed out!";
      case "decline":
        return "You declined eSigning the document";
      case "cancel":
        return "You canceled eSigning the document";
      default:
        return "*Check your email in order to eSign documents";
    }
  }

  clearStoredSiteData(): void{
    this.cookieService.delete('mml-auth-token');
    localStorage.removeItem('mmlProfile');
  }
}
